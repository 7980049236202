<template>
  <div class="container">
    <div class="vBox">
      <video ref="v1" id="v1"></video>
      <video ref="v2" id="v2"></video>
      <video ref="v3" id="v3"></video>
    </div>
  </div>
</template>
<script>
import { ipcApiRoute, specialIpcRoute, ipcRenderer } from "@/api/main";
import { get_lead } from "@/api/openlive.js";
import { shuffle } from "@/utils/tools";

export default {
  name: "setting",
  data() {
    return {
      v1: null,
      v2: null,
      v3: null,
      music: null,
      insert: false,
      playVd: "v1",
      localPath: "",
      list: [],
      vList: [],
      audiolist: [],
      liveOption: {},
      token: localStorage.getItem("token"),
    };
  },
  mounted() {
    this.v1 = this.$refs.v1;
    this.v2 = this.$refs.v2;
    this.v3 = this.$refs.v3;
    this.init();
    this.initAudio();
    this.initWatch();
    this.list = JSON.parse(JSON.stringify(this.vList));
    this.$ipc.invoke(ipcApiRoute.getPath).then((r) => {
      this.localPath = r;
      this.playVd = "v1";
    });
  },
  methods: {
    init() {
      this.v1.addEventListener("play", this.onPlay);
      this.v1.addEventListener("ended", this.onEnded);
      this.v1.addEventListener("canplay", this.onCanplay);
      this.v2.addEventListener("play", this.onPlay);
      this.v2.addEventListener("ended", this.onEnded);
      this.v2.addEventListener("canplay", this.onCanplay);
      this.v3.addEventListener("canplay", () => {
        setTimeout(() => {
          this.v1.style.visibility = "hidden";
          this.v2.style.visibility = "hidden";
          this.v3.style.visibility = "visible";
          this.v1.pause();
          this.v2.pause();
          this.v3.play();
        }, 1800);
      });
      this.v3.addEventListener("ended", () => {
        this.v3.style.visibility = "hidden";
        this.v3.pause();
        if (this.playVd == "v1") {
          this.v1.style.visibility = "visible";
          this.v1.play();
        } else {
          this.v2.style.visibility = "visible";
          this.v2.play();
        }
      });
    },

    initWatch() {
      this.$ipc.removeAllListeners(specialIpcRoute.window1ToWindow2);
      this.$ipc.on(specialIpcRoute.window1ToWindow2, (event, arg) => {
        console.log("arg:", arg);
        if (arg.type == "insertVideo") {
          if (arg.insertType == "interval") {
            this.list.splice(1, 0, {
              id: arg.id,
              url: `${this.localPath}\\${arg.id}hc.mp4`,
              goods_id: "",
            });
            if (this.playVd == "v1") {
              this.v2.src = this.list[1].url;
            } else {
              this.v1.src = this.list[1].url;
            }
            // ***********
          } else {
            this.v3.src = `${this.localPath}\\${arg.id}hc.mp4`;
          }
        } else if (arg.type == "playAudio") {
          this.audiolist.push(arg.audioUrl);
          if (this.audiolist.length == 1) this.playAud();
        } else {
          this.list = [];
          this.liveOption = arg.option;
          let arr = arg.livelist;
          if (arr.length == 1) {
            arr.push(arr[0]);
          }
          arr.map((i) => {
            this.vList.push({
              id: i.id,
              cord_id: i.cord_id || 0,
              url: `${this.localPath}\\${i.id}hc.mp4`,
              goods_id: i.goods_id,
            });
          });
          if (this.liveOption.israndom) {
            this.list.push(...shuffle(JSON.parse(JSON.stringify(this.vList))));
          } else {
            this.list.push(...this.vList);
          }
          this.v1.src = this.list[0].url;
          this.getLead();
        }
      });
    },
    insertVd() {
      console.log("开始插入");
      this.v3.src = this.localPath + "\\650hc.mp4";
    },
    onPlay() {
      if (this.playVd == "v1") {
        this.v2.src = this.list[1].url;
      } else {
        this.v1.src = this.list[1].url;
      }
      this.sendTosubWindow(this.list[0].goods_id);
    },
    onEnded(e) {
      // console.log("onEnded", e.path[0].id, this.list);
      this.playVd = e.path[0].id == "v1" ? "v2" : "v1";
      this.list.shift();
      if (this.list.length <= 2) {
        if (this.liveOption.israndom) {
          console.log(111);
          this.list.push(...shuffle(JSON.parse(JSON.stringify(this.vList))));
        } else {
          console.log(222);
          this.list.push(...this.vList);
        }
        console.log("this.list", this.list);
      }
      this.onCanplay();
    },
    onCanplay() {
      if (this.playVd == "v1") {
        this.v1.style.visibility = "visible";
        this.v2.style.visibility = "hidden";
        this.v2.pause();
        this.v1.play();
      } else {
        this.v1.style.visibility = "hidden";
        this.v2.style.visibility = "visible";
        this.v1.pause();
        this.v2.play();
      }
      // 卡片开关设置********
      if (this.list[0].cord_id > 0 && this.liveOption.showProduct) {
        this.$ipc.invoke(ipcApiRoute.showShop, { type: this.liveOption.showProduct, num: this.list[0].cord_id }); // 点击卡片
      }
    },
    initAudio() {
      this.music = new Audio();
      this.music.volume = 1;
      this.music.addEventListener("ended", this.audioEnded);
      this.music.addEventListener("error", this.audioEnded);
    },
    getLead() {
      setInterval(() => {
        get_lead({
          live_id: this.liveOption.live_id,
          token: this.token,
        }).then((res) => {
          if (res.code == "200") {
            console.log(res.data);
            if (res.data.url) {
              this.audiolist.push(res.data.url);
              if (this.audiolist.length == 1) {
                this.playAud();
              }
            }
          } else {
            this.$message.error(res.msg);
          }
        });
      }, this.liveOption.lead_times * 1000);
    },
    playAud() {
      if (this.audiolist.length > 0) {
        this.music.src = this.audiolist[0];
        this.music.play();
        this.v1.volume = 0;
        this.v2.volume = 0;
      }
    },
    audioEnded() {
      this.audiolist.shift();
      if (this.audiolist.length > 0) {
        this.playAud();
      } else {
        this.v1.volume = 1;
        this.v2.volume = 1;
      }
    },
    sendTosubWindow(goodId) {
      // 获取主窗口id
      this.$ipc.invoke(ipcApiRoute.getWCid, "main").then((id) => {
        this.mainWCid = id;
        this.$ipc.sendTo(this.mainWCid, specialIpcRoute.window2ToWindow1, { msg: "video change", goodId });
      });
    },
  },
};
</script>
<style lang="less" scoped>
.vBox {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

video {
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  visibility: hidden;
}
</style>
